import React, { useContext } from "react"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav"

const Layout = ({ children }) => {
  const { navData } = useContext(NavContext) || { navData: {} }

  return (
    <>
      <Header navData={navData?.mainNavigation?.navigation} />
      {/* <SEO title="Test"></SEO> */}
      <Helmet
        bodyAttributes={{
          class: "theme-default",
        }}
      />
      <main>{children}</main>
      <Footer navData={navData?.footer?.navigation} />
    </>
  )
}

export default Layout
