import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faXing,
  faTwitter,
  faLinkedinIn,
  faFacebookSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

const Footer = ({ navData }) => {
  return (
    <footer className="pt-16 bg-gray-300">
      {/* Footer Head */}
      <section className="">
        <div className="max-w-screen-xl mx-auto mb-16 px-grid">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 px-grid sm:mb-0 sm:w-1/2">
              <Link className="inline-block" link="/">
                {/* <Image
                  className="inline-block h-16"
                  image={{
                    filename: bmeLogo,
                    alt: "BME Logo",
                  }}
                /> */}
              </Link>
            </div>

            <div className="flex items-end w-full px-grid sm:justify-end sm:w-1/2 ">
              {/* Twitter */}
              <Link
                link="https://www.google.de"
                className="inline-block h-6 mr-8 text-gray-800 hover:text-primary"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" className="" />
              </Link>
              {/* Xing */}
              <Link
                link="https://www.google.de"
                className="inline-block h-6 mr-8 text-gray-800 hover:text-primary"
              >
                <FontAwesomeIcon icon={faXing} size="2x" className="" />
              </Link>
              {/* LinkedIn */}
              <Link
                link="https://www.google.de"
                className="inline-block h-6 mr-8 text-gray-800 hover:text-primary"
              >
                <FontAwesomeIcon icon={faLinkedinIn} size="2x" className="" />
              </Link>
              {/* Twitter */}
              <Link
                link="https://www.google.de"
                className="inline-block h-6 mr-8 text-gray-800 hover:text-primary"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  size="2x"
                  className=""
                />
              </Link>
              <Link
                link="https://www.google.de"
                className="inline-block h-6 mr-8 text-gray-800 hover:text-primary"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" className="" />
              </Link>
              <Button link="/" btnType="primary" className="-mb-4">
                Kontakt
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Nav */}
      <section className="">
        <div className="max-w-screen-xl mx-auto mb-16 px-grid">
          {
            <div className="flex flex-row flex-wrap -mx-grid">
              {navData?.map((nav, index) => (
                <div
                  className="w-full mb-8 px-grid sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  key={index}
                >
                  <ul>
                    {nav.content?.map((item, index) => (
                      <li key={index}>
                        <Link
                          className={
                            item.primary
                              ? "text-lg font-bold text-primary hover:text-primary-hover"
                              : "hover:text-primary hover:underline"
                          }
                          link={item.link}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          }
        </div>
      </section>
      {/* Footer Meta-Nav */}
      <section className="text-white bg-primary">
        <div className="max-w-screen-xl mx-auto px-grid">
          <div className="flex flex-row flex-wrap py-4 -mx-grid">
            <div className="w-full px-grid sm:w-1/2">
              <span>© {new Date().getFullYear()} - BME e.V.</span>
            </div>
            <div className="w-full px-grid sm:text-right sm:w-1/2">
              <Link className="py-2 hover:underline" link="/">
                Impressum
              </Link>
              <Link className="py-2 ml-8 hover:underline" link="/">
                Datenschutz
              </Link>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
