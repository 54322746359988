import React, { useState } from "react"
import bmeLogo from "../../../../images/bme-logo.png"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import SearchBar from "./SearchBar"

import flagDe from "../../../../images/de.svg"
import flagEn from "../../../../images/gb.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faCaretDown,
  faShoppingCart,
  faSearch,
  faTimes,
  faPencilAlt,
} from "@fortawesome/pro-solid-svg-icons"

const Header = ({ navData }) => {
  const [menuState, setMenuState] = useState(null)
  const [dropdownState, setDropdownState] = useState(null)
  const [searchOpenState, setSearchOpenState] = useState(false)

  const menuClickHandler = (state) => {
    if (menuState === state) {
      setMenuState(null)
    } else {
      setMenuState(state)
    }
  }
  const dropdownClickHandler = (index) => {
    if (dropdownState === index) {
      setDropdownState(null)
    } else {
      setDropdownState(index)
    }
  }
  const dropdownBlurHandler = () => {
    setDropdownState(null)
  }
  const searchClickHandler = (index) => {
    setDropdownState(null)
    if (searchOpenState === true) {
      setSearchOpenState(false)
    } else {
      setSearchOpenState(true)
    }
  }

  // Umschalten vom meinBME-Menü
  let loggedIn = false

  return (
    <>
      <header className="relative block w-full h-32">
        <div className="absolute block w-full h-32 bg-white border-b border-gray-400 z-nav-30">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-row flex-wrap w-full max-w-screen-xl px-grid">
              {/* logo */}
              <Link className="my-4 lg:my-8" link="/">
                <Image className="inline-block h-8 lg:h-16" image={bmeLogo} />
              </Link>
              {/* burger-Menu */}
              <button
                tabIndex={"open"}
                className="my-auto ml-auto -mr-4 text-gray-700 cursor-pointer lg:hidden"
                onClick={() => menuClickHandler("open")}
              >
                <span className="inline-block w-10 h-16 px-2 py-4 mx-2">
                  <FontAwesomeIcon icon={faBars} size="2x" className="" />
                </span>
              </button>
              {/* navigation */}
              <nav
                className={
                  "absolute z-nav-40 left-0 right-0 flex-col flex-no-wrap flex-1 block mt-16 font-bold bg-white border-t border-gray-400 lg:border-none lg:bg-transparent lg:mt-0 lg:ml-4 lg:static lg:flex-row h-32 w-full lg:flex " +
                  (menuState === "open" ? "block" : "hidden")
                }
              >
                {/* meta navigation */}
                <div className="relative flex border-b border-gray-400 lg:h-32 px-grid lg:ml-auto lg:-mr-4 lg:order-last lg:border-none">
                  <div className="flex mr-auto whitespace-nowrap lg:flex-row-reverse lg:absolute lg:top-0 lg:right-0 lg:mr-0">
                    <div className="relative my-6 mr-4">
                      <button
                        className="inline-block w-auto font-bold bg-gray-300 border border-gray-300 whitespace-nowrap px-grid hover:text-primary focus:outline-none"
                        onClick={() => dropdownClickHandler("meinbme")}
                      >
                        Mein BME
                      </button>
                      {/* meinBME Menu */}
                      {loggedIn === false ? (
                        <div
                          className={`absolute left-0 lg:left-auto lg:right-0 overflow-hidden pointer-events-none`}
                        >
                          <div
                            className={`px-4 py-4 text-base leading-loose font-normal bg-white border border-gray-300 pointer-events-auto transition-all duration-300 transform ${
                              dropdownState !== "meinbme" && "-translate-y-full"
                            }`}
                          >
                            <form className="flex flex-col ">
                              <label className="font-bold" htmlFor="email">
                                E-Mail-Adresse
                              </label>
                              <input
                                className="px-2 py-1 mb-2 bg-gray-100 shadow-inner"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="E-Mail-Adresse"
                                required
                              ></input>
                              <label className="font-bold" htmlFor="password">
                                Passwort
                              </label>
                              <input
                                className="px-2 py-1 bg-gray-100 shadow-inner"
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Passwort"
                                required
                              ></input>
                              <Link
                                className="mb-2 text-primary hover:text-primary-hover"
                                link="/"
                              >
                                Passwort vergessen?
                              </Link>
                              <span>
                                <input
                                  type="checkbox"
                                  id="stayloggedin"
                                  name="stayloggedin"
                                />
                                <label className="ml-2" htmlFor="stayloggedin">
                                  Angemeldet bleiben
                                </label>
                              </span>
                              <input
                                className="btn btn-primary"
                                type="submit"
                                value="Anmelden"
                              ></input>
                              <hr className="my-4 border-primary" />
                              <Link
                                className="text-primary hover:text-primary-hover"
                                link="/"
                              >
                                Registrieren
                              </Link>
                              <Link
                                className="text-primary hover:text-primary-hover"
                                link="/"
                              >
                                Ich habe eine Aktivierungscode
                              </Link>
                              <Link
                                className="text-primary hover:text-primary-hover"
                                link="/"
                              >
                                Was ist meinBME?
                              </Link>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`absolute left-0 lg:left-auto lg:right-0 overflow-hidden pointer-events-none`}
                        >
                          <div
                            className={`px-4 py-4 text-base leading-loose font-normal bg-white border border-gray-300 pointer-events-auto transition-all duration-300 transform ${
                              dropdownState !== "meinbme" && "-translate-y-full"
                            }`}
                          >
                            <span className="block text-sm text-gray-500">
                              Willkommen, Oliver Katzer
                            </span>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Startseite
                            </Link>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Meine Daten verwalten
                            </Link>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Meine Bestellungen
                            </Link>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Fachinformationen für Mitglieder
                            </Link>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              BME Benefits
                            </Link>
                            <hr className="my-4 border-primary" />
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Downloads für Delegierte
                            </Link>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Regionen-Super-Administration
                            </Link>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Mitgliedschaftsanträge
                            </Link>
                            <hr className="my-4 border-primary" />
                            <span className="relative block text-sm text-gray-500">
                              Meine Regionen
                              <Link
                                className="absolute right-0 ml-auto text-primary hover:text-primary-hover"
                                link="/"
                              >
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  size="1x"
                                  className=""
                                />
                              </Link>
                            </span>
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Rhein-Main-Region
                            </Link>
                            <hr className="my-4 border-primary" />
                            <Link
                              className="block text-primary hover:text-primary-hover"
                              link="/"
                            >
                              Abmelden
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="my-6 mr-4 xrelative">
                      <button
                        tabIndex={"lang"}
                        className="right-0 inline-block w-16 px-2 bg-white border border-gray-300 z-nav-20 lg:w-24 whitespace-nowrap lg:px-4 hover:text-primary focus:outline-none"
                        onClick={() => dropdownClickHandler("lang")}
                      >
                        <Image
                          className="inline-block w-6 mr-2"
                          image={flagDe}
                        />
                        <span className="hidden lg:inline-block">DE</span>
                        <span
                          className={`
                            inline-block w-3 h-4 ml-1 origin-center transition duration-300 ${
                              dropdownState === "lang"
                                ? "transform rotate-180"
                                : ""
                            }
                          `}
                        >
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            size="1x"
                            className="block"
                          />
                        </span>
                      </button>
                      <div
                        className={`absolute overflow-hidden pointer-events-none`}
                      >
                        <div
                          className={` text-base leading-loose font-normal pointer-events-auto transition-all duration-300 transform  ${
                            dropdownState === "lang" ? "" : "-translate-y-full"
                          }`}
                        >
                          <Link
                            className="inline-block w-16 px-2 font-normal bg-white border border-gray-300 lg:w-24 whitespace-nowrap lg:px-4 hover:text-primary"
                            link="/en"
                          >
                            <Image
                              className="inline-block w-6 mr-2"
                              image={flagEn}
                            />
                            <span className="hidden lg:inline-block">EN</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link
                    link="/"
                    className="inline-block w-20 h-20 pt-6 pb-6 mt-auto text-right px-grid hover:text-primary"
                  >
                    <span>0 </span>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      size="1x"
                      className=""
                    />
                  </Link>
                  <button
                    onClick={() => searchClickHandler("search")}
                    className="inline-block w-12 h-20 pt-6 pb-6 mt-auto -mr-4 px-grid hover:text-primary"
                  >
                    <FontAwesomeIcon icon={faSearch} size="1x" className="" />
                  </button>
                </div>
                {/* Search-Field */}
                <SearchBar searchOpenState={searchOpenState}>
                  <button
                    onClick={() => searchClickHandler("search")}
                    className="flex w-12 py-4 px-grid hover:text-primary focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimes} size="1x" className="" />
                  </button>
                </SearchBar>
                {/* main-navigation 
                
                
                */}
                {navData?.map((item, index) => (
                  <div className="mt-auto" key={index}>
                    {/* navigation-item */}
                    <button
                      key={index}
                      tabIndex={index}
                      className="flex w-full py-4 font-bold bg-gray-100 border-b border-gray-400 cursor-pointer px-grid md:block lg:py-6 lg:px-3 hover:text-primary lg:border-none lg:bg-transparent lg:w-auto focus:outline-none"
                      onClick={() => dropdownClickHandler(index)}
                    >
                      {item.title}
                      <span
                        className={
                          "inline-block w-3 mt-2 lg:mt-0 h-3 origin-center ml-auto md:w-3 md:h-3 md:ml-1 transition duration-300 " +
                          (dropdownState === index && "transform rotate-180")
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          size="1x"
                          className="block -mt-0.5"
                        />
                      </span>
                    </button>
                    {item.content && (
                      <div
                        //onBlur={() => dropdownBlurHandler()}
                        className={`left-0 right-0 h-0 lg:h-auto overflow-hidden bg-white lg:bg-transparent pointer-events-none lg:absolute transition-all duration-500 ${
                          dropdownState === index
                            ? "h-full border-b lg:border-b-0"
                            : " "
                        }`}
                      >
                        <div
                          className={` text-base leading-loose font-normal pointer-events-auto lg:bg-gray-100 border-b border-gray-400 transition-all duration-300 transform  ${
                            dropdownState === index ? "" : "-translate-y-full"
                          }`}
                        >
                          <div className="w-full mx-auto max-w-none lg:max-w-screen-lg xl:max-w-screen-xl lg:py-8">
                            <div className="flex flex-col flex-wrap -mx-grid lg:justify-center sm:flex-row">
                              {item.content.map((col, index) => (
                                <div
                                  className="w-full px-grid sm:w-1/2 md:w-1/3 lg:w-3/12"
                                  key={index}
                                >
                                  <ul>
                                    {col.content.map((subitem, index) => (
                                      <li key={index}>
                                        <Link
                                          link={subitem.link}
                                          className={
                                            "block px-grid py-2 whitespace-nowrap " +
                                            (subitem.primary
                                              ? "font-bold text-primary hover:text-black"
                                              : "hover:text-primary")
                                          }
                                        >
                                          {subitem.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* Search Overlay */}
      {searchOpenState !== false && (
        <div
          className={`absolute hidden inset-0 z-nav-20 bg-black bg-opacity-75 cursor-pointer lg:block`}
          onClick={() => searchClickHandler("search")}
        ></div>
      )}
    </>
  )
}

export default Header
