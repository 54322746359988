import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const SearchBar = ({ searchOpenState, children }) => {
  const [query, setQuery] = useState("")
  const [searchResults, setSearchResults] = useState(null)
  const [error, setError] = useState(null)

  const MIN_QUERY_LENGTH = 3

  const handleChange = (e) => {
    e.preventDefault()
    setQuery(e.target.value)
  }

  const handleSearch = useCallback(() => {
    query.length >= MIN_QUERY_LENGTH
      ? axios
          .get(`${process.env.SEARCH_API}?q=${query}&lang=de`, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            setSearchResults(res.data)
            //console.log(searchResults)
          })
          .catch((err) => {
            setError(err)
            console.error(error)
          })
      : setSearchResults(null)
  }, [query, error])

  useEffect(() => {
    handleSearch()
  }, [handleSearch])

  return (
    <div
      className={`absolute z-nav-50 left-0 right-0 lg:h-full top-0 bg-white transition-all duration-300 transform ${
        searchOpenState === false &&
        "translate-x-full lg:translate-x-0 lg:-translate-y-full"
      }`}
    >
      <div className="max-w-screen-xl mx-auto lg:h-full px-grid">
        <div className="flex flex-row items-end py-4 lg:h-full -mx-grid">
          <form className="relative flex-1 px-grid">
            <input
              className="w-full px-2 py-2 bg-gray-100 shadow-inner"
              type="text"
              onChange={handleChange}
              placeholder="Suchbegriff"
            ></input>
            {/* Quicklinks */}
            {searchResults === null && query.length < MIN_QUERY_LENGTH && (
              <div
                className={`absolute left-0 right-0 px-4 font-normal ${
                  searchOpenState === false && "hidden"
                }`}
              >
                <ul className="block py-2 bg-white border-b border-l border-r border-gray-200">
                  <li className="px-4 font-bold ">Quicklinks</li>
                  <li>
                    <Link
                      className="block px-4 hover:text-primary hover:bg-gray-100"
                      link="/"
                    >
                      Startseite
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {/* Results */}
            {searchResults && query.length >= MIN_QUERY_LENGTH && (
              <div
                className={`absolute left-0 right-0 px-4 font-normal ${
                  searchOpenState === false && "hidden"
                }`}
              >
                <ul className="block py-2 bg-white border-b border-l border-r border-gray-200">
                  {searchResults &&
                    searchResults?.results?.map((result, index) => (
                      <React.Fragment key={index}>
                        <li className="">
                          {result.is_group ? (
                            <Link
                              className="block px-4 font-bold text-primary hover:text-black hover:bg-gray-100"
                              link={result.link}
                            >
                              {result.title}
                            </Link>
                          ) : (
                            <Link
                              className="block px-4 pt-2 hover:text-primary hover:bg-gray-100"
                              link={result.link}
                            >
                              <span className="block text-xs leading-none text-gray-500">
                                {result.pub_date}
                              </span>
                              {result.title}
                            </Link>
                          )}
                        </li>
                        <li className=""></li>
                      </React.Fragment>
                    ))}
                </ul>
              </div>
            )}
          </form>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SearchBar
